import {Link} from 'gatsby'
import Container from '../components/container'
import Fade from 'react-reveal/Fade'
import Layout from '../components/layout'
import React from 'react'
import styled from 'styled-components'

export default props => {
  return (
    <Layout documentTitlePrefix="Thanks" isIndexPage={false} pageName="thanks" showScrollToTopBtn={false}>
      <Container>
        <Fade>
          <StyledThanksMessage>
            <div className="thanks-text">
              <p>Thanks for contacting us.</p>
              <p>We&rsquo;ll be in touch soon.</p>
            </div>

            <p className="home-link-wrapper">
              <Link to="/">Back to homepage</Link>
            </p>
          </StyledThanksMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

const StyledThanksMessage = styled.div`
  font-weight: 100;
  margin-top: 10rem;
  text-align: center;

  .thanks-text {
    font-size: 1.8rem;
    margin-bottom: 2rem;

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .home-link-wrapper {
    font-size: 1.4rem;

    a {
      text-decoration: underline;
    }
  }
`
